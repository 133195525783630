import tlds from 'tlds';
import { z } from 'zod';

import { EMAIL_MAX_LENGTH, NAME_MAX_LENGTH } from '@lib/validators/patterns';

const maxLength = {
  name: NAME_MAX_LENGTH,
  email: EMAIL_MAX_LENGTH,
};

export const isValidLength = (value: string, type: 'email' | 'name'): boolean =>
  maxLength[type] ? value?.length <= maxLength[type] : false;

const EmailSchema = z
  .string({ invalid_type_error: 'email_invalid', required_error: 'email_invalid' })
  .email({ message: 'email_invalid' });

const NetsuiteEmailSchema = EmailSchema.max(maxLength.email, { message: 'email_too_long' }).refine(
  (v) => {
    const domain = v.split('@')[1] ?? '';
    const splitDomain = domain.split('.');
    const tld = splitDomain[splitDomain.length - 1];
    return !!tld && tlds.includes(tld);
  },
  { message: 'email_invalid' }
);

/**
 * Netsuite has rules for emails
 * - max length
 * - valid TLDs
 * @param email
 * @returns
 */
export const validateNetsuiteEmail = (email: string): { success: true } | { success: false; error: string } => {
  const result = NetsuiteEmailSchema.safeParse(email);

  if (!result.success) {
    return { success: false, error: result.error.errors[0].message };
  }
  return { success: true };
};

/**
 * Sometimes we only need to check email regex or existence.
 * For example, at login.
 */
export const validateEmailFormatOnly = (email: string): { success: true } | { success: false; error: string } => {
  const result = EmailSchema.safeParse(email);
  if (!result.success) {
    return { success: false, error: result.error.errors[0].message };
  }
  return { success: true };
};

export const validateDob = (dob: Date | string | null): boolean => {
  return !Number.isNaN(Date.parse(dob as any));
};
