import pick from 'lodash/pick';
import Head from 'next/head';
import { FC, Fragment, ImgHTMLAttributes } from 'react';

import { Image, ImageBreakpoint, ResponsiveImage } from '@commerce/types/common';
import { Maybe, Nullable } from '@lib/utility-types';

import getTailwindConfig from './get-tailwind-config';
import { logger } from './logger';
import { getQueryStringFromUrl } from './query-string';

const twBreakpoints = getTailwindConfig('theme.screens');

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    fetchPriority?: 'high' | 'low' | 'auto';
  }
}

export const breakpoints = {
  xs: parseInt(twBreakpoints.xs, 10) || 375,
  sm: parseInt(twBreakpoints.sm, 10) || 480,
  md: parseInt(twBreakpoints.md, 10) || 768,
  lg: parseInt(twBreakpoints.lg, 10) || 1024,
  xl: parseInt(twBreakpoints.xl, 10) || 1280,
  xxl: parseInt(twBreakpoints['2xl'], 10) || 1536,
  xxxl: parseInt(twBreakpoints['3xl'], 10) || 1920,
};

export type ImageSet = {
  default: ResponsiveImage;
  // MIME type
  [type: string]: ResponsiveImage;
};

export type ImageOptions = {
  className?: string;
  // class to be applied on <picture> wrapper (if applicable)
  pictureClass?: string;
  alt?: string;
  aspectRatio?: string;
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
  width?: string | number;
  height?: string | number;
  key?: string;
  priority?: boolean;
} & Pick<ImgHTMLAttributes<HTMLImageElement>, 'loading' | 'aria-hidden'>;

type ContentfulFormat = 'jpg' | 'png' | 'webp' | 'gif' | 'avif';

const generateSourceSet = ({ xs, sm, md, lg, xl, xxl, xxxl, original }: ResponsiveImage, type?: string) => (
  <Fragment key={original.url}>
    {xs && <source type={type} media={`(max-width: ${breakpoints.xs}px)`} srcSet={xs.url} />}
    {sm && <source type={type} media={`(max-width: ${breakpoints.sm}px)`} srcSet={sm.url} />}
    {md && <source type={type} media={`(max-width: ${breakpoints.md}px)`} srcSet={md.url} />}
    {lg && <source type={type} media={`(max-width: ${breakpoints.lg}px)`} srcSet={lg.url} />}
    {xl && <source type={type} media={`(max-width: ${breakpoints.xl}px)`} srcSet={xl.url} />}
    {xxl && <source type={type} media={`(max-width: ${breakpoints.xxl}px)`} srcSet={xxl.url} />}
    {/* if xxl is set, this will be the default image on larger (> xl) viewport */}
    {xxxl && (
      <>
        <source type={type} media={`(max-width: ${breakpoints.xxxl}px)`} srcSet={xxxl.url} />
        <source type={type} media={`(min-width: ${breakpoints.xxxl + 1}px)`} srcSet={xxxl.url} />
      </>
    )}
    {type && original && <source type={type} srcSet={original.url} />}
  </Fragment>
);

const PreloadLinkComponent: FC<{
  url: string;
  type?: string;
  breakpoint?: ImageBreakpoint;
  previousBreakpoint?: ImageBreakpoint;
}> = ({ breakpoint, previousBreakpoint, url, ...rest }) => (
  <Head>
    <link rel="preload" as="image" media={getLinkMediaQuery(breakpoint, previousBreakpoint)} href={url} {...rest} />
  </Head>
);

const PreloadHead: FC<{ responsiveImage: ResponsiveImage; type: Nullable<string> }> = ({ responsiveImage, type }) => {
  const { original: _, ...restPropsResponsiveImage } = responsiveImage;

  return (
    <>
      {Object.entries(restPropsResponsiveImage)
        .filter(([, image]) => !!image)
        .map(([breakpoint, image], i, restEntries) => {
          const [previousBreakpoint] = restEntries[i - 1] ?? [];
          return (
            <PreloadLinkComponent
              key={image.url}
              breakpoint={breakpoint as ImageBreakpoint}
              previousBreakpoint={previousBreakpoint as ImageBreakpoint}
              type={type || undefined}
              url={image.url}
            />
          );
        })}
      {/* if xxl is set, this will be the default image on larger (> xl) viewport */}
      {restPropsResponsiveImage.xxxl && (
        <PreloadLinkComponent
          previousBreakpoint="xxxl"
          type={type || undefined}
          url={restPropsResponsiveImage.xxxl.url}
        />
      )}
    </>
  );
};

export function getLinkMediaQuery(
  breakpoint: Maybe<ImageBreakpoint>,
  previousBreakpoint: Maybe<ImageBreakpoint>
): Maybe<string> {
  if (!breakpoint && !previousBreakpoint) {
    return undefined;
  }

  const maxWidth = (breakpoint && `(max-width: ${breakpoints[breakpoint]}px)`) ?? '';
  const minWidth = (previousBreakpoint && `(min-width: ${breakpoints[previousBreakpoint] + 1}px)`) ?? '';
  return minWidth && maxWidth ? `${minWidth} and ${maxWidth}` : `${minWidth}${maxWidth}`;
}

function parseContentfulImgUrl(url: string) {
  return {
    baseUrl: url.split('?')[0],
    query: getQueryStringFromUrl(url),
  };
}

export const getContentfulImgUrl = (url: string, width?: string | number, format?: ContentfulFormat) => {
  if (!url) {
    return '';
  }

  const { baseUrl, query } = parseContentfulImgUrl(url);
  const w = width || query.w;
  const fm = format || query.fm;

  // default format to png (when width specified with no foramt) to ensure contentful images API can generate processed images
  const param = { w, fm: w && !fm ? 'png' : fm };
  const queryString = (Object.keys(param) as (keyof typeof param)[]).reduce((p, key) => {
    if (param[key]) {
      return `${p}${p ? '&' : ''}${key}=${param[key]}`;
    }
    return p;
  }, '');
  return `${baseUrl}${queryString ? '?' : ''}${queryString}`;
};

export function renderImageTag(image: Image, options?: ImageOptions) {
  if (!image?.url) {
    return null;
  }

  const { className, alt, width, height, objectFit, aspectRatio, key, loading, priority, ...props } = options || {};

  return (
    <img
      src={image.url}
      key={key}
      alt={image?.description || alt || image.alt}
      className={className}
      style={{ aspectRatio, objectFit }}
      width={width}
      height={height}
      loading={loading}
      fetchPriority={priority ? 'high' : 'auto'}
      {...props}
    />
  );
}

function renderContentfulImage(image: Image, options?: ImageOptions) {
  if (!image?.url) {
    return null;
  }

  const { url, alt } = image;

  const webpResImg = { original: { url: getContentfulImgUrl(url, undefined, 'webp'), alt } };

  return (
    <picture key={options?.key} className={options?.pictureClass}>
      {generateSourceSet(webpResImg, 'image/webp')}
      {renderImageTag(image, options)}
    </picture>
  );
}

export function extractImageProvider(src: string): 'contentful' | 'bigcommerce' | null {
  try {
    const url = new URL(src);

    if (url.hostname.endsWith('bigcommerce.com')) {
      return 'bigcommerce';
    }

    if (url.hostname.endsWith('ctfassets.net')) {
      return 'contentful';
    }
  } catch {
    // invalid URL
    return null;
  }

  return null;
}

export function renderImage(image: Image, options?: ImageOptions) {
  if (!image?.url) {
    return null;
  }

  const provider = extractImageProvider(image.url);

  if (provider === 'contentful') {
    return renderContentfulImage(image, options);
  }

  return renderImageTag(image, options);
}

export function renderResponsiveImage(imageSet: ImageSet, options?: ImageOptions) {
  const {
    default: { original },
  } = imageSet;

  return (
    <picture key={original.url} className={options?.pictureClass}>
      {Object.keys(imageSet)
        .filter((type) => type !== 'default')
        .map((key) => generateSourceSet(imageSet[key], key))}
      {/* to let browser pick other type imgset first and 'png' default as fallback */}
      {generateSourceSet(imageSet.default)}
      {renderImageTag(original, options)}
      {options?.priority &&
        Object.keys(imageSet)
          .filter((type) => type !== 'default')
          .map((type) => <PreloadHead key={type} responsiveImage={imageSet[type]} type={type} />)}
    </picture>
  );
}

type BreakpointSelector = Array<keyof Omit<ResponsiveImage, 'original'>>;

export function getContentfulResponsiveImage(
  originalImage: Image,
  mobileImage?: Image,
  format?: ContentfulFormat,
  includedBreakpoints?: BreakpointSelector
): ResponsiveImage {
  // stop pages from 500-ing if there's a missing original image
  if (!originalImage) {
    // the error here will give us a stack trace
    logger.warn(new Error(`Page requested an image which is missing from contentful`));
    return {
      original: {
        url: '',
      },
    };
  }

  const { url, alt, description } = originalImage;
  const { url: mobileUrl, alt: mobileAlt, description: mobileDescription } = mobileImage || {};

  const responsiveImage = {
    sm: {
      url: getContentfulImgUrl(mobileUrl || url, breakpoints.sm, format),
      alt: mobileAlt || alt,
      description: mobileDescription || description,
    },
    md: {
      url: getContentfulImgUrl(mobileUrl || url, breakpoints.md, format),
      alt: mobileAlt || alt,
      description: mobileDescription || description,
    },
    lg: { url: getContentfulImgUrl(url, breakpoints.lg, format), alt, description },
    xl: { url: getContentfulImgUrl(url, breakpoints.xl, format), alt, description },
    xxl: { url: getContentfulImgUrl(url, breakpoints.xxl, format), alt, description },
    xxxl: { url: getContentfulImgUrl(url, breakpoints.xxxl, format), alt, description },
    original: { url: getContentfulImgUrl(url, undefined, format), alt, description },
  };

  if (includedBreakpoints?.length) {
    return { ...pick(responsiveImage, includedBreakpoints), original: responsiveImage.original };
  }
  return responsiveImage;
}

export function getContentfulImgSet(
  originalImage: Image,
  mobileImage?: Image,
  includedBreakpoints?: BreakpointSelector
): ImageSet {
  return {
    default: getContentfulResponsiveImage(originalImage, mobileImage, undefined, includedBreakpoints),
    'image/webp': getContentfulResponsiveImage(originalImage, mobileImage, 'webp', includedBreakpoints),
  };
}
